import React, { FC } from "react";
import Button from "../../../../../../components/button/Button";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/redux";
import {
  pauseTimer,
  startTimer,
} from "../../../../../../services/TimerService";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";

type ITimerAndStatusProps = {
  work: WorkDetailsModel;
};

export const WorkTimer: FC<ITimerAndStatusProps> = ({ work }) => {
  const dispatch = useAppDispatch();

  const { timer } = useAppSelector((state) => state.timerReducer);

  const buttonTimerVariant = (work: WorkDetailsModel) => {
    if (
      timer?.workId !== work.id &&
      (timer?.timerStatus === "ended" ||
        timer?.timerStatus === null ||
        timer === null)
    ) {
      return (
        <Button
          buttonType={"button"}
          label={"Start timer"}
          fullWidth
          size={"custom"}
          onClick={() => {
            dispatch(
              startTimer({
                workId: work.id,
                customerId: work.customerId,
              }),
            );
          }}
          extraClasses={
            "normal-case min-h-fit py-0 p-0 px-4 h-8 w-full max-w-full"
          }
        />
      );
    }
    if (
      timer?.workId === work.id &&
      (timer?.timerStatus === "started" || timer?.timerStatus === "paused")
    ) {
      return (
        <Button
          buttonType={"button"}
          size={"custom"}
          fullWidth
          label={timer?.timerStatus === "paused" ? "Continue" : "Pause"}
          onClick={() => {
            if (timer?.timerStatus === "paused") {
              dispatch(
                startTimer({ workId: work.id, customerId: work.customerId }),
              );
            } else {
              dispatch(pauseTimer(timer?.id || ""));
            }
          }}
          extraClasses={
            "normal-case min-h-fit py-0 p-0 px-4 h-8 w-full max-w-full"
          }
        />
      );
    } else return <></>;
  };
  return (
    <div className={"pb-4"}>
      <div>{buttonTimerVariant(work)}</div>
    </div>
  );
};
