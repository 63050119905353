import { ChevronDown } from "baseui/icon";
import classNames from "classnames";
import moment from "moment";
import React, { FC } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import {
  getInitials,
  getInitialsFromFullName,
  showStatus,
} from "../../../constants/constants";
import { WorkModel } from "../../../factories/works/models/work.model";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";
import { StatusesDropdown } from "../dropdown/StatusesDropdown";
import { getStatusColor } from "../dropdown/utils";
import { WorkTableHeaderItem, workTableHeaderItems } from "./types/work-table";
import { getWorkProgress } from "./utils/getWorkProgress";
import {
  HomeFilter,
  HomeTab,
} from "../../../factories/works/models/home-enums";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

type Props = {
  works: WorkModel[];
  myWorkHome: boolean | undefined;
  filter?: HomeTab | HomeFilter.OverDue;
};

type Params = {
  page: string;
  sortBy: string;
  sortOrder: string;
  "home-tab"?: string;
  statusName?: string;
  workType?: string;
  assignee?: string;
};

export const WorkTable: FC<Props> = ({ works, myWorkHome, filter }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || "1";
  const activeTab = searchParams.get("home-tab") || "";
  const sortBy = searchParams.get("sortBy");
  const sortOrder = searchParams.get("sortOrder");
  const statusName = searchParams.get("statusName") || "";
  const workType = searchParams.get("workType") || "";
  const assignee = searchParams.get("assignee") || "";

  const dueDateWord = (
    filter: HomeTab | HomeFilter.OverDue,
    work: WorkModel,
  ) => {
    if (filter === "ThisWeek") {
      return work.tasksAssignedThisWeek === 0
        ? "No subtasks assigned to me"
        : work.tasksAssignedThisWeek === 1
        ? work.tasksAssignedThisWeek + " subtask to do this week"
        : work.tasksAssignedThisWeek + " subtasks to do this week";
    } else if (filter === "NextWeek") {
      return work.tasksAssignedNextWeek === 0
        ? "No subtasks assigned to me"
        : work.tasksAssignedNextWeek === 1
        ? work.tasksAssignedNextWeek + " subtask to do next week"
        : work.tasksAssignedNextWeek + " subtasks to do next week";
    } else {
      return work.tasksAssignedDueLater === 0
        ? "No subtasks assigned to me"
        : work.tasksAssignedDueLater === 1
        ? work.tasksAssignedDueLater + " subtask to do later"
        : work.tasksAssignedDueLater + " subtasks to do later";
    }
  };

  function checkDate(dateString: string): string {
    const date = new Date(dateString);
    const year: number = date.getUTCFullYear();

    if (year > 2023) {
      return "Since " + moment(date).format("MMM DD, YYYY");
    }

    return "";
  }
  const onClickSort = (item: WorkTableHeaderItem) => {
    const params: Params = {
      page,
      sortBy: item.sort,
      sortOrder: "",
      "home-tab": activeTab,
      statusName: statusName,
      workType: workType,
      assignee: assignee,
    };
    if (!activeTab) {
      delete params?.["home-tab"];
    }
    if (!statusName) {
      delete params?.statusName;
    }
    if (!workType) {
      delete params?.workType;
    }
    if (!assignee) {
      delete params?.assignee;
    }
    if (sortBy === item.sort) {
      params.sortOrder = sortOrder === "asc" ? "desc" : "asc";

      setSearchParams(params);
    } else {
      params.sortOrder = "asc";
      setSearchParams(params);
    }
  };

  const isOverdue = (work: WorkModel) => {
    return moment().startOf("day").unix() > moment(work.dueDate).unix();
  };

  if (!works?.length) {
    return (
      <div className={"mb-5 w-full text-center font-semibold"}>
        There are no work items
      </div>
    );
  }

  return (
    <table className={"w-full"}>
      <thead className={"border-b border-gray-300"}>
        <tr>
          {workTableHeaderItems.map((item) => (
            <th
              key={item.title}
              className={"p-4 text-start text-sm font-semibold text-[#9FA1AD]"}>
              <div
                className={classNames(
                  "flex items-center gap-2",
                  !item?.hideSort && "cursor-pointer",
                )}
                onClick={() => !item?.hideSort && onClickSort(item)}>
                <div
                  className={classNames(
                    "whitespace-nowrap",
                    !item.hideSort && sortBy === item.sort && "text-[#7C66F0]",
                  )}>
                  {item.title}
                </div>
                {!item.hideSort && (
                  <>
                    {sortBy === item.sort &&
                      (sortOrder === "asc" ? (
                        <FaArrowUp size={8} color={"#7C66F0"} />
                      ) : (
                        <FaArrowDown size={8} color={"#7C66F0"} />
                      ))}
                  </>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {works?.map((work) => (
          <tr
            key={work?.workId}
            onClick={() => navigate("/work/" + work?.workId)}
            className={
              "border-b border-gray-300 text-start text-sm transition-colors hover:bg-gray-100"
            }>
            <td className={"max-w-[300px] px-4 py-2"}>
              <div
                className={
                  "text-purple w-fit max-w-full cursor-pointer truncate font-semibold hover:underline"
                }>
                {work?.workName}
                <div className={"text-sm font-normal text-gray-500"}>
                  {myWorkHome && filter
                    ? dueDateWord(filter, work)
                    : work?.tasksAssignedToMe +
                      "/" +
                      work?.taskCounts +
                      " subtasks assigned to me"}
                </div>
              </div>
            </td>
            <td
              className={
                "whitespace-nowrap px-4 py-2 font-semibold text-gray-800"
              }>
              {work?.workType}
            </td>
            <td className={"max-w-[200px] truncate px-4 py-2"}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  const link = navigateToContact({
                    type: work?.businessContactId ? "organization" : "contact",
                    id: work?.businessContactId || "",
                    customerId: work?.customerId || "",
                  });
                  navigate(link);
                }}
                className={
                  "flex w-fit max-w-[200px] cursor-pointer items-center gap-2 truncate"
                }>
                <AvatarPlaceholder
                  size={"2xs"}
                  label={getInitialsFromFullName(work?.customerName)}
                  type={work?.businessContactId ? "purple" : "blue"}
                />
                <div
                  className={
                    "font-semibold hover:text-[#7C66F0] hover:underline"
                  }>
                  {work?.customerName}
                </div>
              </div>
            </td>
            <td
              className={"px-4 py-2"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              {work?.jobStatus && (
                <StatusesDropdown
                  workTypeId={work?.workTypeId || ""}
                  workId={work?.workId || ""}
                  triggerElement={
                    <div
                      className={
                        "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
                      }>
                      <div
                        className={"flex h-[10px] w-[10px] rounded-full"}
                        style={{
                          backgroundColor: getStatusColor(
                            work?.jobStatus || "",
                          ),
                        }}
                      />
                      <div>{showStatus(work)}</div>
                      <ChevronDown size={16} />
                    </div>
                  }
                />
              )}
            </td>
            <td className={"px-4 py-2 font-semibold text-gray-800"}>
              {moment(work?.startDate).format("MMM DD, YYYY")}
            </td>
            <td
              className={classNames(
                "px-4 py-2 font-semibold",
                isOverdue(work) ? "text-orange-400" : "text-gray-800",
              )}>
              {moment(work?.dueDate).format("MMM DD, YYYY")}
            </td>
            <td className={"w-[100px] px-4 py-2 text-center"}>
              <div>
                {getWorkProgress({
                  taskCounts: work?.taskCounts || 0,
                  completedTaskCounts: work?.completedTaskCounts || 0,
                })}
                %
              </div>
              <div className={"h-[5px] w-[100px] rounded-[10px] bg-gray-400"}>
                <div
                  style={{
                    width: `${getWorkProgress({
                      taskCounts: work?.taskCounts || 0,
                      completedTaskCounts: work?.completedTaskCounts || 0,
                    })}%`,
                  }}
                  className={"bg-green h-[5px] rounded-[10px]"}
                />
              </div>
            </td>
            <td className={"px-4 py-2"}>
              <div
                className={
                  "flex w-fit max-w-[200px] items-center gap-2 truncate"
                }>
                <AvatarPlaceholder
                  size={"2xs"}
                  label={getInitials(
                    work?.userFirstName || "",
                    work?.userLastName || "",
                  )}
                />
                <div className={"font-semibold text-gray-800"}>
                  {work?.userFirstName} {work?.userLastName}
                </div>
              </div>
            </td>
            <td
              className={
                "whitespace-nowrap px-4 py-2 font-semibold text-gray-800"
              }>
              {work?.repeatType}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
