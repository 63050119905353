import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  HomeFilter,
  HomeTab,
} from "../../../factories/works/models/home-enums";
import { useAppSelector } from "../../../redux/redux";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useGetFirmsWorksCountsQuery,
  useGetHomeFirmWorkStatsQuery,
  useGetHomeMyWorkStatsQuery,
  useGetHomeWorksQuery,
} from "../../../slices/WorkSlice";
import { WorkTableSort } from "../../works/components/types/work-table";
import { WorkList } from "../../works/components/WorkList";
import { AddWorkModal } from "../../works/modals/AddWork.modal";
import { HomeStats } from "./HomeStats";
import { getStatusesStats } from "../utils/getStatusesStats";

export const HomeFirmWorks: FC<unknown> = () => {
  const { user } = useAppSelector((state) => state.appReducer);

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("home-tab") as HomeTab;
  const activeFilter = searchParams.get("home-filter") as HomeFilter;
  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const {
    data: counts,
    isLoading: isLoadingCounts,
    isError,
  } = useGetFirmsWorksCountsQuery({
    orgId: getLinkedFirm()?.orgId || "",
    userId: user?.id || "",
    isMyWork: false,
  });

  const { data: works, isLoading: isLoadingWorks } = useGetHomeWorksQuery({
    pageNumber: page,
    pageSize: 20,
    sortBy: sortBy,
    sortOrder: sortOrder,
    dueDates:
      activeFilter === HomeFilter.OverDue ? HomeFilter.OverDue : activeTab,
    status: activeFilter === HomeFilter.OverDue ? undefined : activeFilter,
    orgId: getLinkedFirm()?.orgId || "",
    currentUserId: user.id,
  });

  const [isAddWork, setIsAddWork] = useState(false);

  useEffect(() => {
    if (!activeTab) {
      setSearchParams({
        "home-tab": HomeTab.ThisWeek,
        page: page?.toString() || "",
        sortBy: sortBy?.toString() || "",
        sortOrder: sortOrder?.toString() || "",
      });
    }
  }, [activeTab]);

  useEffect(() => {
    setSearchParams({
      "home-tab": activeTab || "",
      page: "1",
      sortBy: WorkTableSort.StartDate,
      sortOrder: "desc",
    });
  }, []);

  const handleChangeHomeFilter = (filter: HomeFilter) => {
    setSearchParams({
      "home-tab": activeTab || "",
      "home-filter": activeFilter === filter ? "" : filter,
      page: page?.toString() || "",
      sortBy: sortBy?.toString() || "",
      sortOrder: sortOrder?.toString() || "",
    });
  };
  let stats = counts;
  const statusesStats = getStatusesStats({ activeTab, stats });

  if (isLoadingCounts) {
    return <LottieLoading />;
  }

  if (isError) {
    return null;
  }
  return (
    <>
      <div className={"grid h-full w-full grid-rows-[max-content_1fr] gap-6"}>
        <HomeStats
          thisWeek={counts?.thisWeek?.total || 0}
          nextWeek={counts?.nextWeek?.total || 0}
          later={counts?.later?.total || 0}
          allOpen={counts?.allOpen?.total || 0}
          completed={counts?.completed || 0}
        />
        <WorkList works={works || []} isLoading={isLoadingWorks} hasPages>
          <div
            className={
              "grid grid-cols-[1fr_max-content] items-start gap-2 px-8 pt-6"
            }>
            <div className={"flex flex-col gap-4"}>
              <div className={"font-medium"}>
                {user?.userProfile?.firstName} {user?.userProfile?.lastName}
              </div>
              {activeTab !== HomeTab.Completed && (
                <div className={"flex items-center gap-2"}>
                  <div
                    onClick={() => handleChangeHomeFilter(HomeFilter.Ready)}
                    className={classNames(
                      "border-purple h-fit w-fit cursor-pointer rounded-[15px] border px-6 text-base font-semibold",
                      activeFilter === HomeFilter.Ready
                        ? "bg-purple text-white"
                        : "text-gray-800",
                    )}>
                    Ready {statusesStats.ready}
                  </div>
                  <div
                    onClick={() =>
                      handleChangeHomeFilter(HomeFilter.InProgress)
                    }
                    className={classNames(
                      "border-purple h-fit w-fit cursor-pointer rounded-[15px] border px-6 text-base font-semibold",
                      activeFilter === HomeFilter.InProgress
                        ? "bg-purple text-white"
                        : "text-gray-800",
                    )}>
                    In Progress {statusesStats.inProgress}
                  </div>
                  <div
                    onClick={() => handleChangeHomeFilter(HomeFilter.Waiting)}
                    className={classNames(
                      "border-purple h-fit w-fit cursor-pointer rounded-[15px] border px-6 text-base font-semibold",
                      activeFilter === HomeFilter.Waiting
                        ? "bg-purple text-white"
                        : "text-gray-800",
                    )}>
                    Waiting {statusesStats.waiting}
                  </div>
                  {activeTab === HomeTab.ThisWeek && (
                    <div
                      onClick={() => handleChangeHomeFilter(HomeFilter.OverDue)}
                      className={classNames(
                        "h-fit w-fit cursor-pointer rounded-[15px] border border-[#F15252] px-6 text-base font-semibold",
                        activeFilter === HomeFilter.OverDue
                          ? "bg-[#F15252] text-white"
                          : "text-gray-800",
                      )}>
                      Overdue {statusesStats.overdue}
                    </div>
                  )}
                </div>
              )}
            </div>
            <Button
              buttonType={"button"}
              label={"Add work"}
              onClick={() => setIsAddWork(true)}
            />
          </div>
        </WorkList>
      </div>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
    </>
  );
};
