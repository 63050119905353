import React, { FC, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { useGetOrgQuery } from "../../../services/OrgService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { CustomLink } from "../../../support/CustomLink";
import { GetPageTitle } from "../../../support/ScrollToTop";
import HomeTeamWork from "../HomeTeamWork";
import { HomeFirmWorks } from "./HomeFirmWorks";
import { HomeMyWorks } from "./HomeMyWorks";
import { HomeUserWorks } from "./HomeUserWorks";
import { useAppDispatch, useAppSelector } from "../../../redux/redux";
import { getUser } from "../../../services/AppService";
import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import * as Xstate from "@xstate/react";
import {
  useGetFirmsWorksCountsQuery,
  useGetHomeWorksQuery,
} from "../../../slices/WorkSlice";
import {
  HomeFilter,
  HomeTab,
} from "../../../factories/works/models/home-enums";
import { WorkTableSort } from "../../works/components/types/work-table";

export const HomeView: FC<unknown> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("home-tab") as HomeTab;
  const activeFilter = searchParams.get("home-filter") as HomeFilter;
  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const { data: org, isLoading: isLoadingOrg } = useGetOrgQuery(
    getLinkedFirm()?.orgId || "",
  );
  const dispatch = useAppDispatch();

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { user, isLoadingUser } = useAppSelector((state) => state.appReducer);
  const viewFirmWorkPermission = "4ef8ede7-80d5-43a4-9ad9-a9e08dbdb7c0";

  const { data: counts } = useGetFirmsWorksCountsQuery({
    orgId: getLinkedFirm()?.orgId || "",
    userId: user?.id || "",
    isMyWork: true,
  });

  const { data: countsFirm } = useGetFirmsWorksCountsQuery({
    orgId: getLinkedFirm()?.orgId || "",
    userId: user?.id || "",
    isMyWork: false,
  });

  useEffectOnce(() => {
    document.title = GetPageTitle("Home");
  });

  useEffect(() => {
    dispatch(
      getUser({
        userId: `${userInfoByEmail?.id}`,
        orgId: `${userInfoByEmail?.org?.id}`,
      }),
    );
  }, []);

  if (isLoadingOrg || isLoadingUser) {
    return <LottieLoading />;
  }

  if (!org) {
    return null;
  }

  return (
    <div>
      <div className={"mb-6 text-[24px] font-semibold"}>{org?.legalName}</div>
      <div className={"mb-6 flex items-center gap-8"}>
        <CustomLink to={"/home/my-work"} count={counts?.allOpen?.total || 0}>
          {"My work"}
        </CustomLink>
        <CustomLink
          to={"/home/firm-work"}
          count={countsFirm?.allOpen?.total || 0}>
          {"Firm's work"}
        </CustomLink>
        <CustomLink to={"/home/team-work"}>{"Team's work"}</CustomLink>
      </div>
      <Routes>
        <Route path={""} element={<Outlet />}>
          <Route
            index
            element={<Navigate to={"/home/my-work"} replace={true} />}
          />
          <Route path={"/my-work"} element={<HomeMyWorks />} />
          <Route
            path={"/firm-work"}
            element={
              (user.permissionIds &&
                user.permissionIds.includes(viewFirmWorkPermission)) ||
              user?.userRoleEnum?.userRole === "Admin" ? (
                <HomeFirmWorks />
              ) : (
                <div
                  className={
                    "flex h-[75vh] items-center justify-center text-[42px] font-bold text-gray-400"
                  }>
                  You don`t have access to this page
                </div>
              )
            }
          />

          <Route path={"/team-work"} element={<HomeTeamWork />} />
          <Route path={"/user-work/:userId"} element={<HomeUserWorks />} />
        </Route>
      </Routes>
    </div>
  );
};
