import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
interface ITimer {
  id: string;
  workId: string;
  customerId: string;
  duration: number;
  timerStatus: "started" | "paused" | "ended";
  workTypeId: null;
  work: { name: string };
  contactName: string;
  businessContactId: string;
  contactId: string;
  assignedUserId: string;
  notes: string;
}
interface IInitialState {
  timer: ITimer | null;
  isLoading: boolean;
  error: string;
}
const initialState: IInitialState = {
  timer: null,
  isLoading: false,
  error: "",
};

export const getTimer = createAsyncThunk(
  "timer/getTimer",
  async (
    { userId, orgId }: { userId: string; orgId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/get-by-userId/${userId}?orgId=${orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const endTimer = createAsyncThunk(
  "timer/endTimer",
  async (
    body: {
      id: string;
      duration: number;
      workId: string;
      notes: string;
      assignedUserId: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/end-timer`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body: JSON.stringify(body),
        method: "POST",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const pauseTimer = createAsyncThunk(
  "timer/pauseTimer",
  async (timeId: string, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/pause-timer?timeId=${timeId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const startTimer = createAsyncThunk(
  "timer/startTimer",
  async (
    { workId, customerId }: { workId: string; customerId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/start-timer?workId=${workId}&customerId=${customerId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const updateStatusTimer = createAsyncThunk(
  "timer/updateStatusTimer",
  async (
    { status, workId }: { status: string; workId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/timer-status?status=${status}&workId=${workId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    setTimer(
      state,
      //TODO add types for localTimer for saving workId customerId to use in others components for stating timer
      action: PayloadAction<ITimer | null>,
    ) {
      state.timer = action.payload;
    },
    setTimerDuration(
      state,
      //TODO add types for localTimer for saving workId customerId to use in others components for stating timer
      action: PayloadAction<number>,
    ) {
      if (state.timer) {
        state.timer.duration = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimer.pending, (state) => {
        state.isLoading = true;
        state.error = "";
      })
      .addCase(getTimer.fulfilled, (state, action) => {
        state.isLoading = false;
        //TODO recheck this logic
        state.timer =
          action.payload.timerStatus === "ended" ||
          action.payload.timerStatus === null
            ? {
                id: "",
                notes: "",
                timerStatus: "ended",
                assignedUserId: "",
                duration: 0,
                workId: "",
                work: { name: "" },
                businessContactId: null,
                workTypeId: "",
                contactId: null,
                contactName: "",
              }
            : action.payload.timerStatus === "paused"
            ? {
                ...action.payload,
                contactName:
                  action.payload.businessContact?.companyName ||
                  `${action.payload.contact.firstName} ${action.payload.contact.lastName}`,
              }
            : {
                ...action.payload,
                contactName:
                  action.payload.businessContact?.companyName ||
                  `${action.payload.contact.firstName} ${action.payload.contact.lastName}`,
                duration:
                  action.payload.duration +
                  moment().utc().unix() -
                  moment(action.payload.date).unix(),
              };
      })
      .addCase(startTimer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timer = action.payload;
      })
      .addCase(pauseTimer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.timer = action.payload;
      });
  },
});

export const { setTimerDuration, setTimer } = timerSlice.actions;

export default timerSlice.reducer;
