import classNames from "classnames";
import moment from "moment/moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { FaCheckCircle } from "react-icons/fa";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Link } from "react-router-dom";

import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import {
  getInitials,
  getInitialsFromFullName,
  timeFormatter,
} from "../../constants/constants";
import { FormSection } from "../../support/FormSection";
import { ITime } from "../../types/types";
import { navigateToContact } from "../contacts/utils/navigateToContacts";
import EditTime from "./EditTime";
import { Time } from "./InvoiceTime";
import { TimeFilters } from "./TimeFilters";
import { TimeListHeaderItem, timeListHeaderItems } from "./types/time-list";

type Props = {
  buttonTimerVariant: () => JSX.Element;
  getData: () => Promise<void>;
  filterTime: ITime[] | undefined;
  billableHours:
    | { billedAmount: number; billedHours: number; unBilledHours: number }
    | undefined;
  filter: Time;
  setFilter: Dispatch<SetStateAction<Time>>;
};
export const TimesListView: FC<Props> = ({
  buttonTimerVariant,
  getData,
  filterTime,
  billableHours,
  filter,
  setFilter,
}) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [currentTime, setCurrentTime] = useState<ITime | null>(null);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);

  const totalRate = (value: string) => {
    const hours = Number(value.split("h").slice(0, -1));
    const minutes = Number(value?.split("h")?.pop()?.replace("m", "")) / 60;
    return hours + minutes;
  };

  const onClickSort = (item: TimeListHeaderItem) => {
    setFilter((prev) => ({
      ...prev,
      sortBy: item.sort,
      sortOrder:
        item.sort === prev.sortBy
          ? prev.sortOrder === "asc"
            ? "desc"
            : "asc"
          : "asc",
    }));
  };

  const getSortedData = (): ITime[] => {
    if (!filterTime) {
      return [];
    }
    if (filter.sortBy === "Total") {
      return [...filterTime]?.sort((a, b) => {
        if (filter.sortOrder === "asc") {
          return (
            (a.assignedUser.hourlyRate || 0) *
              totalRate(timeFormatter(a.duration || 0)) -
            (b.assignedUser.hourlyRate || 0) *
              totalRate(timeFormatter(b.duration || 0))
          );
        } else {
          return (
            (b.assignedUser.hourlyRate || 0) *
              totalRate(timeFormatter(b.duration || 0)) -
            (a.assignedUser.hourlyRate || 0) *
              totalRate(timeFormatter(a.duration || 0))
          );
        }
      });
    } else {
      return filterTime;
    }
  };

  useEffect(() => {
    void getData();
  }, [filter]);

  return (
    <>
      <div className={"mt-4 flex justify-start gap-6"}>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Billable amount</div>
          <div className={"text-2xl font-bold"}>
            ${billableHours?.billedAmount}
          </div>
        </div>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Billed hours</div>
          <div className={"text-2xl font-bold"}>
            {billableHours && timeFormatter(billableHours?.billedHours)}
          </div>
        </div>
        <div
          className={
            "flex w-[300px] flex-col items-center justify-center gap-1 rounded-[8px] bg-white px-2 py-8 shadow-md shadow-gray-400"
          }>
          <div className={"text-lg text-gray-500"}>Unbilled hours</div>
          <div className={"text-2xl font-bold"}>
            {billableHours && timeFormatter(billableHours?.unBilledHours)}
          </div>
        </div>
      </div>
      <FormSection
        name={""}
        showHeading={false}
        extraCx={"pb-0 px-0 overflow-visible"}>
        <div className={"flex justify-between px-6"}>
          <TimeFilters filter={filter} setFilter={setFilter} />
          <div>{buttonTimerVariant()}</div>
        </div>
        <table className={"w-full"}>
          <thead className={"border-b border-gray-300"}>
            <tr>
              {timeListHeaderItems.map((item) => (
                <th
                  key={item.title}
                  className={"p-4 text-start text-sm text-gray-500"}>
                  <div
                    className={classNames(
                      "flex items-center gap-2",
                      !item?.hideSort && "cursor-pointer",
                    )}
                    onClick={() => !item?.hideSort && onClickSort(item)}>
                    <div className={"whitespace-nowrap"}>{item.title}</div>
                    {!item.hideSort && (
                      <>
                        {filter.sortBy === item.sort &&
                          (filter.sortOrder === "asc" ? (
                            <HiChevronUp size={24} />
                          ) : (
                            <HiChevronDown size={24} />
                          ))}
                      </>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={"text-sm"}>
            {getSortedData()?.map((time) => (
              <tr
                key={time?.id}
                className={
                  "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                }>
                <td className={"items-center px-[16px] py-[8px]"}>
                  <div className={"flex items-center"}>
                    <div
                      className={
                        "max-w-[300px] items-center whitespace-normal"
                      }>
                      <Link
                        to={`/work/${time?.work?.id}`}
                        className={
                          "link-primary text-sm font-medium hover:underline"
                        }>
                        {time.work?.name}
                      </Link>
                    </div>
                  </div>
                </td>
                <td className={"items-center px-[16px] py-[8px]"}>
                  <div className={"flex items-center"}>
                    {time.contactId ? (
                      <div className={"flex items-center text-[14px]"}>
                        <AvatarPlaceholder
                          size={"2xs"}
                          label={getInitials(
                            time?.contact?.firstName || "",
                            time?.contact?.lastName || "",
                          )}
                        />
                        <div className={"pl-2 text-[14px] normal-case"}>
                          <Link
                            to={
                              time.contact
                                ? navigateToContact({
                                    type: "contact",
                                    customerId: time?.work?.customerId || "",
                                  })
                                : ""
                            }
                            className={
                              "link-primary text-sm font-medium hover:underline"
                            }>
                            {time.contact
                              ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                              : "Contact name"}
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className={"flex items-center text-[14px]"}>
                        <AvatarPlaceholder
                          size={"2xs"}
                          type={"purple"}
                          label={getInitialsFromFullName(
                            time?.businessContact?.companyName || "",
                          )}
                        />
                        <div className={"pl-2 text-[14px] normal-case"}>
                          <Link
                            to={
                              time.businessContact
                                ? `/organization/${time.businessContactId}`
                                : ""
                            }
                            className={
                              "link-primary text-sm font-medium hover:underline"
                            }>
                            {time.businessContact
                              ? time.businessContact.companyName
                              : "Contact name"}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
                <td
                  className={
                    "items-center px-[16px] py-[8px] text-[14px] font-semibold"
                  }>
                  {time.work?.service?.name}
                </td>
                <td
                  className={
                    "link-primary cursor-pointer items-center px-[16px] py-[8px] text-sm font-medium hover:underline"
                  }
                  onClick={() => {
                    setCurrentTime(time);
                    setIsOpenEdit(true);
                  }}>
                  {timeFormatter(time.duration)}
                </td>
                <td
                  className={
                    " min-w-[120px] items-center px-[16px] py-[8px] text-[14px] font-semibold"
                  }>
                  {moment(time.date).format("YYYY-MM-DD")}
                </td>
                <td className={"px-[16px] py-[8px] text-[14px] font-semibold"}>
                  {Math.trunc(
                    time.assignedUser.hourlyRate *
                      totalRate(timeFormatter(time.duration)),
                  )}
                  $
                </td>
                <td className={"px-[16px] py-[8px] text-[14px] font-semibold"}>
                  {time.isBilled ? (
                    <div className={"flex items-center gap-2"}>
                      <FaCheckCircle className={"text-green-500"} /> Billed
                    </div>
                  ) : (
                    "Saved"
                  )}
                </td>
                <td className={"relative bg-transparent px-4 py-2"}>
                  <div className={"flex items-center text-sm"}>
                    <AvatarPlaceholder
                      size={"2xs"}
                      label={getInitials(
                        time.assignedUser?.userProfile?.firstName || "",
                        time.assignedUser?.userProfile?.lastName || "",
                      )}
                    />
                    <div
                      className={
                        "max-w-[300px] truncate whitespace-nowrap pl-2 text-sm font-semibold normal-case text-gray-800"
                      }>
                      {time.assignedUser &&
                        `${time.assignedUser?.userProfile?.firstName}
              ${time.assignedUser?.userProfile?.middleName} 
              ${time.assignedUser?.userProfile?.lastName}`}
                    </div>
                  </div>
                </td>
                <td
                  className={"px-[16px] py-[8px] text-[14px] "}
                  onClick={() => {
                    setCollapsedItems((prev) =>
                      collapsedItems.includes(time?.id)
                        ? prev.filter((item) => item !== time?.id)
                        : [...prev, time?.id],
                    );
                  }}>
                  <div
                    className={`${
                      !collapsedItems.includes(time?.id)
                        ? "...  max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[100px] 2xl:max-w-[100px]"
                        : " max-w-[100px] whitespace-pre-line"
                    }`}>
                    {time.notes}
                  </div>
                </td>
              </tr>
            ))}
            <tr className={"sticky bottom-0 left-0 bg-gray-100"}>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td
                className={
                  "px-4 py-2 text-end text-sm font-bold text-gray-800"
                }>
                Total:
              </td>
              <td
                className={
                  "whitespace-nowrap px-4 py-2 text-sm font-bold text-gray-800"
                }>
                {timeFormatter(getSortedData()?.[0]?.totalDuration || 0)}
              </td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2 text-sm font-bold text-gray-800"}>
                $
                {(getSortedData()?.[0]?.totalAmount || 0).toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
              <td className={"px-4 py-2"}></td>
            </tr>
          </tbody>
        </table>
      </FormSection>
      {isOpenEdit && currentTime && (
        <EditTime
          closeModal={() => setIsOpenEdit(false)}
          time={currentTime}
          onSuccess={getData}
        />
      )}
    </>
  );
};
