import classNames from "classnames";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Card } from "../../../components/card/Card";
import { HomeTab } from "../../../factories/works/models/home-enums";
import { WorkTableSort } from "../../works/components/types/work-table";
import { StatsCard } from "../../../components/card/StatsCard";

type Props = {
  thisWeek: number;
  nextWeek: number;
  later: number;
  allOpen: number;
  completed: number;
};

export const HomeStats: FC<Props> = ({
  thisWeek,
  nextWeek,
  later,
  allOpen,
  completed,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("home-tab") as HomeTab;

  const handleChangeTab = (tab: HomeTab) => {
    setSearchParams({
      "home-tab": tab,
      page: "1",
      sortBy: WorkTableSort.WorkName,
      sortOrder: "asc",
    });
  };

  useEffect(() => {
    if (!activeTab) {
      handleChangeTab(HomeTab.ThisWeek);
    }
  }, [activeTab]);

  return (
    <div className={"grid grid-cols-5 gap-6"}>
      <div onClick={() => handleChangeTab(HomeTab.ThisWeek)}>
        <StatsCard
          cardName={"This week"}
          cardCounts={thisWeek}
          isActive={activeTab === HomeTab.ThisWeek}
        />
      </div>
      <div onClick={() => handleChangeTab(HomeTab.NextWeek)}>
        <StatsCard
          cardName={"Next week"}
          cardCounts={nextWeek}
          isActive={activeTab === HomeTab.NextWeek}
        />
      </div>
      <div onClick={() => handleChangeTab(HomeTab.DueLater)}>
        <StatsCard
          cardName={"Later"}
          cardCounts={later}
          isActive={activeTab === HomeTab.DueLater}
        />
      </div>
      <div onClick={() => handleChangeTab(HomeTab.AllOpen)}>
        <StatsCard
          cardName={"All open"}
          cardCounts={allOpen}
          isActive={activeTab === HomeTab.AllOpen}
        />
      </div>
      <div onClick={() => handleChangeTab(HomeTab.Completed)}>
        <StatsCard
          cardName={"Completed"}
          cardCounts={completed}
          isActive={activeTab === HomeTab.Completed}
        />
      </div>
    </div>
  );
};
