export enum WorkTableSort {
  WorkName = "Name",
  Contact = "Contact",
  Status = "Status",
  Progress = "Progress",
  Repeats = "Repeats",
  WorkType = "WorkType",
  Assignee = "Assignee",
  StartDate = "StartDate",
  DueDate = "DueDate",
}

export enum WorkTableTitle {
  WorkName = "Work Name",
  Contact = "Contact",
  Status = "Status",
  Progress = "Progress",
  Repeats = "Repeats",
  WorkType = "Work Type",
  Assignee = "Assignee",
  StartDate = "Start Date",
  DueDate = "Due Date",
}

export type WorkTableHeaderItem = {
  title: WorkTableTitle;
  sort: WorkTableSort;
  hideSort?: boolean;
};

export const workTableHeaderItems: WorkTableHeaderItem[] = [
  {
    title: WorkTableTitle.WorkName,
    sort: WorkTableSort.WorkName,
  },
  {
    title: WorkTableTitle.WorkType,
    sort: WorkTableSort.WorkType,
  },
  {
    title: WorkTableTitle.Contact,
    sort: WorkTableSort.Contact,
  },
  {
    title: WorkTableTitle.Status,
    sort: WorkTableSort.Status,
  },
  {
    title: WorkTableTitle.StartDate,
    sort: WorkTableSort.StartDate,
  },
  {
    title: WorkTableTitle.DueDate,
    sort: WorkTableSort.DueDate,
  },
  {
    title: WorkTableTitle.Progress,
    sort: WorkTableSort.Progress,
    hideSort: true,
  },
  {
    title: WorkTableTitle.Assignee,
    sort: WorkTableSort.Assignee,
  },
  {
    title: WorkTableTitle.Repeats,
    sort: WorkTableSort.Repeats,
    hideSort: true,
  },
];
