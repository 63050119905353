import { Dialog, Transition } from "@headlessui/react";
import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DATES } from "../../app/commonOps/CommonDateOps";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  deleteTime,
  getAllTimes,
  getTimesByWork,
} from "../../services/AppService";
import {
  endTimer,
  setTimer,
  setTimerDuration,
  startTimer,
} from "../../services/TimerService";
import { OutlineButton } from "../../components/button/OutlineButton";
import Button from "../../components/button/Button";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import InputTime from "../../components/inputs/inputTime";
import { useParams } from "react-router-dom";
import { useLazyGetTimesByWorkQuery } from "../../slices/WorkSlice";

interface IAddTimerProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AddTimer: React.FC<IAddTimerProps> = ({ isOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appReducer);
  const { workId } = useParams();

  const { timer } = useAppSelector((state) => state.timerReducer);

  const [getTime] = useLazyGetTimesByWorkQuery();

  const [time, setTime] = useState({
    h: (moment.duration(timer?.duration, "seconds").hours() || 0).toString(),
    m: (moment.duration(timer?.duration, "seconds").minutes() || 0).toString(),
  });

  const { register, handleSubmit } = useForm({
    defaultValues: {
      date: DATES.today(),
      notes: "",
    },
  });

  const onSubmit = async () => {
    try {
      await dispatch(
        endTimer({
          id: timer?.id || "",
          duration: Number(time.h) * 3600 + Number(time.m) * 60,
          workId: timer?.workId || "",
          notes: timer?.notes || "",
          assignedUserId: timer?.assignedUserId || null,
        }),
      );
      dispatch(setTimer(null));
    } catch (e) {}
    dispatch(getAllTimes({ id: getLinkedFirm()?.orgId, userId: user.id }));
    dispatch(
      getTimesByWork({
        jobId: `${timer?.workId}`,
        orgId: `${getLinkedFirm()?.orgId}`,
      }),
    );
    if (workId) {
      getTime({ workId, orgId: getLinkedFirm()?.orgId || "" });
    }
    closeModal();
  };
  useEffect(() => {
    if (isOpen) {
      setTime({
        h: (
          moment.duration(timer?.duration, "seconds").hours() || 0
        ).toString(),
        m: (
          moment.duration(timer?.duration, "seconds").minutes() || 0
        ).toString(),
      });
    }
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as={"div"} className={"relative z-10"} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter={"ease-out duration-300"}
          enterFrom={"opacity-0"}
          enterTo={"opacity-100"}
          leave={"ease-in duration-200"}
          leaveFrom={"opacity-100"}
          leaveTo={"opacity-0"}>
          <div className={"fixed inset-0 bg-black bg-opacity-25"} />
        </Transition.Child>

        <div className={"fixed inset-0 overflow-y-auto"}>
          <div
            className={
              "flex min-h-full items-center justify-center p-4 text-center"
            }>
            <Transition.Child
              as={Fragment}
              enter={"ease-out duration-300"}
              enterFrom={"opacity-0 scale-95"}
              enterTo={"opacity-100 scale-100"}
              leave={"ease-in duration-200"}
              leaveFrom={"opacity-100 scale-100"}
              leaveTo={"opacity-0 scale-95"}>
              <Dialog.Panel
                className={
                  "z-index-100 w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                }>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className={"w-full text-center"}>Confirm Time</div>

                    <div className={"mb-4"}>
                      <div className={"label-text mb-2"}>Contact</div>
                      <div
                        className={
                          "input input-bordered flex w-full items-center disabled:border-[#D4D6D9] disabled:bg-transparent"
                        }>
                        {timer?.contactName}
                      </div>
                    </div>
                    <div className={"mb-4"}>
                      <div className={"label-text mb-2"}>Work</div>
                      <div
                        className={
                          "input input-bordered flex w-full items-center disabled:border-[#D4D6D9] disabled:bg-transparent"
                        }>
                        {/*  {timer.work.name}*/}
                      </div>
                    </div>
                    <div className={"mb-4 flex justify-between space-x-[20px]"}>
                      <div className={"w-full"}>
                        <div className={"label-text mb-2"}>Date</div>
                        <div>
                          <div
                            className={
                              "input input-bordered flex items-center"
                            }>
                            {moment(DATES.today()).format("YYYY-MM-DD")}
                          </div>
                        </div>
                      </div>
                      <div className={"w-full"}>
                        <div className={"label-text mb-2"}>Duration</div>
                        <div>
                          <InputTime value={time} onChange={setTime} />
                        </div>
                      </div>
                    </div>
                    <div className={"mb-4"}>
                      <div className={"label-text mb-2"}>Notes</div>
                      <div>
                        <textarea
                          autoFocus={true}
                          {...register("notes")}
                          className={
                            "input input-bordered min-h-[100px] w-full py-[8px]"
                          }
                        />
                      </div>
                    </div>
                    <div className={"flex justify-between"}>
                      <Button
                        onClick={() => {
                          dispatch(setTimer(null));
                          if (timer?.id) {
                            dispatch(deleteTime(timer));
                          }
                          closeModal();
                        }}
                        buttonType={"button"}
                        colorType={"error"}
                        label={"Delete"}
                        extraClasses={"normal-case"}
                      />
                      <div className={"flex space-x-[10px]"}>
                        <OutlineButton
                          colorType={"neutral"}
                          label={"Cancel"}
                          onClick={() => {
                            dispatch(
                              startTimer({
                                workId: timer?.workId || "",
                                customerId:
                                  timer?.contactId ||
                                  timer?.businessContactId ||
                                  "",
                              }),
                            );
                            closeModal();
                          }}
                          extraClasses={"normal-case"}
                        />
                        <Button
                          buttonType={"submit"}
                          label={"Save"}
                          extraClasses={"normal-case"}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddTimer;
