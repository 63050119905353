import React, { FC } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";

type Props = {
  cardName: string;
  cardCounts: number;
  isActive: boolean;
};
export const StatsCard: FC<Props> = ({ cardName, cardCounts, isActive }) => {
  return (
    <div
      className={classNames(
        "outline flex h-[110px] cursor-pointer flex-col justify-center gap-2 rounded-[10px] bg-white px-5 py-4 outline-1 outline-gray-200",
        isActive ? "border-b-[2px] border-[#7C66F0]" : "",
      )}>
      <div
        className={classNames(
          "text-sm font-semibold",
          isActive ? "text-purple" : "text-[#2B333C]",
        )}>
        {cardName}
      </div>
      <div
        className={classNames(
          "text-[24px] font-semibold",
          isActive ? "text-purple" : "text-[#2B333C]",
        )}>
        {cardCounts}
      </div>
    </div>
  );
};
