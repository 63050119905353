import classNames from "classnames";
import React from "react";
import {
  Link,
  LinkProps,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

type Props = { count?: number } & LinkProps;
export const CustomLink: React.FC<Props> = ({
  children,
  to,
  count,
  ...props
}) => {
  const resolved = useResolvedPath(to);
  const location = useLocation();
  const linkPath = resolved.pathname.split("/").filter((item) => item !== "");
  const locationPath = location.pathname
    .split("/")
    .filter((item) => item !== "");

  const match = linkPath.every((element) => locationPath.includes(element));

  const currentTabClass = match
    ? "border-[#7C66F0] text-[#7C66F0] border-b-[2px]"
    : "";

  return (
    <div>
      <Link
        className={classNames(
          "mr-8 py-2 text-sm font-semibold text-[#2B333CCC] hover:text-[#7C66F0]",
          currentTabClass,
          typeof count === "number" && "flex items-center gap-2.5",
        )}
        to={to}
        {...props}>
        {children}
        {typeof count === "number" && (
          <div
            className={classNames(
              "rounded-lg border border-[#EAEDF3A3] bg-white px-2 py-1.5",
              match && "",
            )}>
            {count}
          </div>
        )}
      </Link>
    </div>
  );
};

interface ICustomPageProps {
  onClick: () => void;
  extraClasses?: string;
  isActive: boolean;
}

export const CustomPage: React.FC<ICustomPageProps> = ({
  onClick,
  isActive,
  extraClasses,
  children,
  ...props
}) => {
  const tabBaseClass = "tab tab-bordered text-[16px]";
  const currentTabClass = isActive ? "tab-active text-[16px]" : "";

  return (
    <div
      onClick={onClick}
      className={`${tabBaseClass} ${currentTabClass} ${extraClasses}`}
      {...props}>
      {children}
    </div>
  );
};
